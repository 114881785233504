<script setup>
  import { Listbox, ListboxButton, ListboxOption, ListboxOptions, Switch, SwitchGroup, SwitchLabel } from '@headlessui/vue'
  import { ErrorMessage, Field, useField } from 'vee-validate'

  const props = defineProps({
    accept: { type: String },
    as: { default: 'div', type: String },
    autocomplete: { type: String },
    custom: { default: false, type: Boolean },
    disabled: { type: Boolean },
    error: { type: String },
    id: { type: String },
    imageClass: { default: 'h-28 w-28 rounded-full', type: String },
    inputClass: { type: String },
    label: { type: String },
    maxlength: { type: String },
    min: { type: String },
    multiple: { type: Boolean },
    name: { type: String },
    options: { type: Array },
    placeholder: { type: String },
    readonly: { type: Boolean },
    required: { type: Boolean },
    rows: { type: String },
    type: { default: 'text', type: String },
    value: { type: undefined }
  })
  const emit = defineEmits(['select'])

  const selectedValue = ref(props.value)
  const selectedValues = ref(props.value || [])

  const switchValue = ref(!!props.value)

  const showPassword = ref(false)

  const field = useField(props.name, undefined)
  const imageInput = ref(null)
  const imageUrl = ref(props.type === 'image' && props.value?.url ? useStrapiMedia(props.value.url) : null)

  const handleImageChange = event => {
    const file = event.target.files[0]
    if (!props.accept.includes(file.type)) return
    field.handleChange(file)

    const reader = new FileReader()
    reader.onload = e => (imageUrl.value = e.target.result)
    reader.readAsDataURL(file)
  }

  const clearImageFile = () => {
    field.handleReset()
    field.setValue(null)
    imageInput.value.value = null
    imageUrl.value = null
  }

  watch(selectedValues, value => emit('select', value))
</script>

<template>
  <component :is="props.as">
    <template v-if="!['checkbox', 'image', 'radio', 'switch'].includes(props.type)">
      <label class="mb-1 block" v-if="props.label" :for="props.id">
        {{ props.label }}<span class="text-zinc-400" v-if="props.required">*</span>
      </label>
    </template>
    <template v-if="props.type === 'select'">
      <Field v-if="!props.custom && !props.multiple" v-slot="{ field, value }" :name="props.name">
        <select
          class="form-select w-full truncate"
          v-bind="field"
          :class="{
            error: !!props.error,
            '!text-zinc-500': !value
          }"
          :disabled="props.disabled"
          :id="props.id"
          :required="props.required"
        >
          <template v-if="props.options">
            <option v-if="props.placeholder" value="">{{ props.placeholder }}</option>
            <option v-for="option in props.options" :key="option.value" :selected="option.selected || option.value === value" :value="option.value">
              {{ option.label }}
            </option>
          </template>
          <slot />
        </select>
      </Field>
      <Field v-model="selectedValue" v-else-if="!props.multiple" v-slot="{ field }" :name="props.name">
        <Listbox class="relative" v-bind="field" v-model="selectedValue" as="div" :multiple="props.multiple">
          <ListboxButton
            as="button"
            :disabled="props.disabled"
            :class="[
              'form-select w-full',
              {
                'border-red-500 bg-red-500/[0.08]': !!props.error,
                'text-zinc-500': !selectedValue
              }
            ]"
          >
            {{ !selectedValue ? props.placeholder : options.find(o => o.value === selectedValue)?.label }}
          </ListboxButton>
          <ListboxOptions
            class="absolute z-10 mt-0.5 max-h-64 w-full max-w-full overflow-auto rounded border border-zinc-300 bg-white shadow outline-none focus:border-viking-400 focus-visible:ring-2 focus-visible:ring-viking-400/50"
          >
            <template v-if="props.options">
              <ListboxOption
                class="cursor-default truncate px-2 py-1 text-zinc-500 hover:bg-zinc-100"
                v-if="props.placeholder && selectedValue"
                key="0"
                value=""
                >{{ props.placeholder }}</ListboxOption
              >
              <ListboxOption
                v-for="option in props.options"
                :key="option.value"
                :value="option.value"
                :class="['cursor-default truncate px-2 py-1 hover:bg-zinc-100', { 'text-zinc-500': option.value === selectedValue }]"
              >
                {{ option.label }}
              </ListboxOption>
            </template>
            <slot />
          </ListboxOptions>
        </Listbox>
      </Field>
      <Field v-model="selectedValues" v-slot="{ field }" v-else multiple :name="props.name">
        <Listbox class="relative" v-bind="field" v-model="selectedValues" as="div" :multiple="props.multiple">
          <ListboxButton
            class="form-select w-full"
            as="button"
            :class="{
              'border-red-500 bg-red-500/[0.08]': !!props.error
            }"
            :disabled="props.disabled"
          >
            <div class="min-h-[1.5rem] text-zinc-500" v-if="!selectedValues.length">{{ props.placeholder }}</div>
            <div class="-mx-1 -my-0.5 inline-flex flex-wrap" v-else>
              <div
                class="m-0.5 inline-flex cursor-pointer items-center space-x-1 whitespace-nowrap rounded-sm bg-viking-400 px-1 py-0.5 text-sm duration-300 hover:bg-viking-500"
                v-for="value in selectedValues"
                :key="value"
                @click.stop="selectedValues = selectedValues.filter(selectedValue => selectedValue !== value)"
              >
                <span>{{ props.options.find(option => option.value === value)?.label ?? value }}</span>
                <Icon class="text-white" name="xmark" size="sm" />
              </div>
            </div>
          </ListboxButton>
          <ListboxOptions
            class="absolute z-10 mt-0.5 max-h-64 w-full max-w-full overflow-auto rounded border border-zinc-300 bg-white shadow outline-none focus:border-viking-400 focus-visible:ring-2 focus-visible:ring-viking-400/50"
          >
            <template v-if="props.options">
              <ListboxOption
                class="cursor-default truncate px-2 py-1 hover:bg-zinc-100"
                v-for="option in props.options"
                :class="{
                  'text-zinc-500': selectedValues.includes(option.value),
                  'text-zinc-300': maxlength && selectedValues.length >= parseInt(maxlength),
                }"
                :key="option.value"
                :value="option.value"
                :disabled="maxlength && selectedValues.length >= parseInt(maxlength) && !selectedValues.includes(option.value)"
                >{{ maxlength && selectedValues.length >= parseInt(maxlength) && !selectedValues.includes(option.value) ?
                  option.label + ' (max atteint)' : 
                  option.label
                }}</ListboxOption
              >
            </template>
            <slot />
          </ListboxOptions>
        </Listbox>
      </Field>
    </template>
    <template v-else-if="props.type === 'switch'">
      <Field v-model="switchValue" :name="props.name">
        <SwitchGroup class="flex items-center space-x-2 self-center" as="div">
          <Switch
            class="relative inline-flex h-6 w-10 shrink-0 items-center rounded-full outline-none duration-300 focus-visible:ring-2 focus-visible:ring-viking-400/50"
            v-model="switchValue"
            :disabled="disabled"
            :class="[switchValue ? 'bg-viking-400' : 'bg-zinc-300']"
          >
            <span class="inline-block h-4 w-4 rounded-full bg-white duration-300" :class="switchValue ? 'translate-x-5' : 'translate-x-1'" />
          </Switch>
          <SwitchLabel v-if="props.label" v-html="props.label" />
        </SwitchGroup>
      </Field>
    </template>
    <template v-else-if="props.type === 'image'">
      <Field :name="props.name">
        <label v-if="!imageUrl" :for="props.id">
          <div
            class="-full relative mx-auto flex aspect-square cursor-pointer flex-col flex-wrap items-center justify-center border bg-zinc-300 duration-300 hover:bg-viking-400"
            :class="[
              props.imageClass,
              {
                'border-red-500': !!props.error
              }
            ]"
          >
            <Icon class="-mt-2" name="camera" size="2x" />
            <p class="mt-1 text-xs font-bold" v-if="props.label">{{ props.label }}</p>
          </div>
        </label>
        <div
          class="-full relative mx-auto aspect-square bg-zinc-300 bg-cover bg-center"
          v-else
          :class="props.imageClass"
          :style="{
            backgroundImage: `url(${imageUrl})`
          }"
        >
          <button
            class="absolute bottom-1 right-1 flex h-8 w-8 items-center justify-center rounded-full bg-viking-400 text-white shadow duration-300 hover:bg-viking-500"
            type="button"
            @click="clearImageFile"
          >
            <Icon name="xmark" />
          </button>
        </div>
        <input
          class="hidden"
          :accept="props.accept"
          :id="props.id"
          ref="imageInput"
          :required="props.required"
          type="file"
          @change="handleImageChange"
        />
      </Field>
    </template>
    <slot v-else>
      <Wrapper class="flex items-center space-x-2" :as="['checkbox', 'radio'].includes(props.type) ? 'div' : null">
        <div class="relative">
          <Field
            class="block"
            :accept="props.accept"
            :as="props.rows ? 'textarea' : 'input'"
            :autocomplete="props.autocomplete"
            :class="[
              {
                'form-checkbox': props.type === 'checkbox',
                'form-input': !['checkbox', 'file', 'radio'].includes(props.type),
                'form-file': props.type === 'file',
                'form-radio': props.type === 'radio',
                error: !!props.error,
                'w-full': !['checkbox', 'radio'].includes(props.type)
              },
              props.inputClass
            ]"
            :disabled="props.disabled"
            :id="props.id"
            :maxlength="props.maxlength"
            :min="props.min"
            :name="props.name"
            :placeholder="props.placeholder"
            :readonly="props.readonly"
            :required="props.required"
            :rows="props.rows"
            :type="props.type === 'password' ? (showPassword ? 'text' : 'password') : props.type"
            @input="field.handleChange"
            :value="props.value"
          />
          <div class="absolute inset-y-0 right-0 flex items-center pr-3 text-sm leading-5" v-if="props.type === 'password'">
            <Icon name="eye" @click="showPassword = true" :class="['m-px text-gray-500', showPassword ? '!hidden' : 'block']" />
            <Icon name="eye-slash" @click="showPassword = false" :class="['text-gray-500', showPassword ? 'block' : '!hidden']" />
          </div>
        </div>
        <template v-if="['checkbox', 'radio'].includes(props.type)">
          <label v-if="props.label" :for="props.id">{{ props.label }}</label>
          <slot v-else name="label"></slot>
        </template>
      </Wrapper>
    </slot>
    <ErrorMessage class="mt-1 inline-block text-sm text-red-500" v-if="!!props.error" :name="props.name" />
  </component>
</template>
